<template>
  <div
    id="alert-additional-content-1"
    class="tw-p-2 tw-border tw-bg-white tw-rounded-lg tw-w-40"
    :class="{
      'request-type-style': shortType === 'REQUEST',
      'accept-type-style': shortType === 'ACCEPTED',
      'reject-type-style': shortType === 'REJECTED',
    }"
    role="alert"
  >
    <div class="tw-flex tw-w-full tw-justify-between">
      <div
        class="tw-flex tw-items-center"
        :class="{
          'tw-text-blue-900': shortType === 'REQUEST',
          'tw-text-green-900': shortType === 'ACCEPTED',
          'tw-text-red-900': shortType === 'REJECTED',
        }"
      >
        <svg
          aria-hidden="true"
          class="tw-w-5 tw-h-5 tw-mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <h3 v-if="shortType === 'REQUEST'" class="tw-text-lg tw-font-medium">
          Request to join
        </h3>
        <h3
          v-else-if="shortType === 'ACCEPTED'"
          class="tw-text-lg tw-font-medium"
        >
          Join request has been accepted
        </h3>
        <h3
          v-else-if="shortType === 'REJECTED'"
          class="tw-text-lg tw-font-medium"
        >
          Join request has been rejected
        </h3>
      </div>
    </div>
    <div
      class="tw-flex tw-items-end tw-mt-2 tw-ml-2"
      :class="{
        'tw-text-blue-900': shortType === 'REQUEST',
        'tw-text-green-900': shortType === 'ACCEPTED',
        'tw-text-red-900': shortType === 'REJECTED',
      }"
    >
      <div class="tw-flex-auto tw-w-full tw-text-sm tw-text-left">
        <div class="">RoomID: {{ notification.RoomID }}</div>
        <div v-if="shortType === 'REQUEST'" class="">
          Request by: {{ notification.NewAgent.ID }}
        </div>
        <div class="">Ref: {{ notification.RefID }}</div>
      </div>
    </div>
    <div class="tw-flex tw-flex-none tw-gap-1 tw-justify-end tw-h-min">
      <button @click="onView" type="button" class="accept-btn">Close</button>
      <button
        v-if="shortType === 'REQUEST'"
        @click="onAccept"
        type="button"
        class="accept-btn"
      >
        Accept
      </button>

      <button
        v-if="shortType === 'REQUEST'"
        @click="onReject"
        type="button"
        class="reject-btn"
      >
        Reject
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestToJoinNotify",
  props: {
    notification: Object,
  },
  data() {
    return {};
  },
  computed: {
    shortType() {
      if (this.notification.Type === "agent-join-request-notification") {
        return "REQUEST";
      } else if (
        this.notification.Type === "agent-join-accepted-notification"
      ) {
        return "ACCEPTED";
      } else if (
        this.notification.Type === "agent-join-rejected-notification"
      ) {
        return "REJECTED";
      }
      return null;
    },
  },
  methods: {
    onAccept() {
      this.$emit("accept");
    },
    onReject() {
      this.$emit("reject");
    },
    onView() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.request-type-style {
  width: 20rem;
  background: #dbeafe;
  border-color: #1e3a8a;
  color: #1e3a8a;
}
.accept-type-style {
  width: 20rem;
  background: #dcfce7;
  border-color: #14532d;
  color: #14532d;
}
.reject-type-style {
  width: 20rem;
  background: #fee2e2;
  border-color: #7f1d1d;
  color: #7f1d1d;
}

.accept-btn,
.reject-btn {
  /* tw-text-blue-900 tw-bg-transparent tw-border tw-border-blue-900
          hover:tw-bg-blue-900 hover:tw-text-white
          focus:tw-ring-4 focus:tw-outline-none focus:tw-ring-blue-200
          tw-font-medium
          tw-rounded-lg
          tw-text-xs
          tw-px-3
          tw-py-1.5
          tw-text-center */
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 0.25rem;
  font-size: 0.75rem;
}
</style>