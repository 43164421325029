var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-p-2 tw-border tw-bg-white tw-rounded-lg tw-w-40",class:{
    'request-type-style': _vm.shortType === 'REQUEST',
    'accept-type-style': _vm.shortType === 'ACCEPTED',
    'reject-type-style': _vm.shortType === 'REJECTED',
  },attrs:{"id":"alert-additional-content-1","role":"alert"}},[_c('div',{staticClass:"tw-flex tw-w-full tw-justify-between"},[_c('div',{staticClass:"tw-flex tw-items-center",class:{
        'tw-text-blue-900': _vm.shortType === 'REQUEST',
        'tw-text-green-900': _vm.shortType === 'ACCEPTED',
        'tw-text-red-900': _vm.shortType === 'REJECTED',
      }},[_c('svg',{staticClass:"tw-w-5 tw-h-5 tw-mr-2",attrs:{"aria-hidden":"true","fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","clip-rule":"evenodd"}})]),(_vm.shortType === 'REQUEST')?_c('h3',{staticClass:"tw-text-lg tw-font-medium"},[_vm._v(" Request to join ")]):(_vm.shortType === 'ACCEPTED')?_c('h3',{staticClass:"tw-text-lg tw-font-medium"},[_vm._v(" Join request has been accepted ")]):(_vm.shortType === 'REJECTED')?_c('h3',{staticClass:"tw-text-lg tw-font-medium"},[_vm._v(" Join request has been rejected ")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-items-end tw-mt-2 tw-ml-2",class:{
      'tw-text-blue-900': _vm.shortType === 'REQUEST',
      'tw-text-green-900': _vm.shortType === 'ACCEPTED',
      'tw-text-red-900': _vm.shortType === 'REJECTED',
    }},[_c('div',{staticClass:"tw-flex-auto tw-w-full tw-text-sm tw-text-left"},[_c('div',{},[_vm._v("RoomID: "+_vm._s(_vm.notification.RoomID))]),(_vm.shortType === 'REQUEST')?_c('div',{},[_vm._v(" Request by: "+_vm._s(_vm.notification.NewAgent.ID)+" ")]):_vm._e(),_c('div',{},[_vm._v("Ref: "+_vm._s(_vm.notification.RefID))])])]),_c('div',{staticClass:"tw-flex tw-flex-none tw-gap-1 tw-justify-end tw-h-min"},[_c('button',{staticClass:"accept-btn",attrs:{"type":"button"},on:{"click":_vm.onView}},[_vm._v("Close")]),(_vm.shortType === 'REQUEST')?_c('button',{staticClass:"accept-btn",attrs:{"type":"button"},on:{"click":_vm.onAccept}},[_vm._v(" Accept ")]):_vm._e(),(_vm.shortType === 'REQUEST')?_c('button',{staticClass:"reject-btn",attrs:{"type":"button"},on:{"click":_vm.onReject}},[_vm._v(" Reject ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }